import React from 'react';
import proj4 from 'proj4';
import cavitesJson from './data/cavites.json';

function App() {
  // Initialisation de proj4
  React.useEffect(() => {
    proj4.defs("EPSG:27572", "+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=2.337229167 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +units=m +no_defs");
  }, []);

  // Fonction de conversion
  const convertToWGS84 = (x, y) => {
    try {
      const result = proj4("EPSG:27572", "WGS84", [x, y]);
      return {
        lat: result[1],
        lon: result[0]
      };
    } catch (error) {
      console.error('Erreur de conversion:', error);
      return null;
    }
  };

  // Position de votre parcelle
  const parcelle = { 
    lat: 50.248531, 
    lon: 3.322798,
    nom: "Votre parcelle ZC 266 - ZI val de calvigny" 
  };

  React.useEffect(() => {
    const linkEl = document.createElement('link');
    linkEl.rel = 'stylesheet';
    linkEl.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';
    document.head.appendChild(linkEl);

    const scriptEl = document.createElement('script');
    scriptEl.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
    document.body.appendChild(scriptEl);

    scriptEl.onload = () => {
      const L = window.L;
      
      // Initialiser la carte
      const map = L.map('map').setView([parcelle.lat, parcelle.lon], 14);
      
      // Ajouter OpenStreetMap
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap contributors'
      }).addTo(map);

      // Marqueur de la parcelle
      L.marker([parcelle.lat, parcelle.lon], {
        icon: L.divIcon({
          className: 'custom-div-icon',
          html: `<div style="background-color: #22c55e; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white;"></div>`,
          iconSize: [15, 15]
        })
      }).addTo(map).bindPopup(`<b>${parcelle.nom}</b>`);

      // Cercle de 500m
      L.circle([parcelle.lat, parcelle.lon], {
        color: '#22c55e',
        fillColor: '#22c55e',
        fillOpacity: 0.1,
        radius: 500
      }).addTo(map);

      // Ajouter les cavités
      cavitesJson.forEach(cavite => {
        const coords = convertToWGS84(cavite.xouvl2e, cavite.youvl2e);
        if (coords) {
          const couleur = 
            cavite.typeCavite === "ouvrage militaire" ? "#ef4444" :
            cavite.typeCavite === "ouvrage civil" ? "#3b82f6" : "#9ca3af";

          L.marker([coords.lat, coords.lon], {
            icon: L.divIcon({
              className: 'custom-div-icon',
              html: `<div style="background-color: ${couleur}; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white;"></div>`,
              iconSize: [15, 15]
            })
          }).addTo(map).bindPopup(`
            <b>${cavite.nomCavite}</b><br>
            Type: ${cavite.typeCavite}<br>
            ${cavite.commentaires}
          `);
        }
      });
    };

    return () => {
      document.head.removeChild(linkEl);
      document.body.removeChild(scriptEl);
    };
  }, []);

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      margin: 0,
      padding: 0,
      display: 'flex'
    }}>
      {/* Sidebar */}
      <div style={{
        width: '300px',
        backgroundColor: 'white',
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        padding: '20px',
        overflowY: 'auto'
      }}>
        <h1 style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '20px'
        }}>
          Analyse des cavités
        </h1>

        {/* Légende */}
        <div style={{
          backgroundColor: '#f8fafc',
          padding: '15px',
          borderRadius: '8px',
          marginBottom: '20px'
        }}>
          <h2 style={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '10px'
          }}>Légende</h2>
          
          {[
            { couleur: '#22c55e', label: 'Votre parcelle' },
            { couleur: '#ef4444', label: 'Ouvrage militaire' },
            { couleur: '#3b82f6', label: 'Ouvrage civil' },
            { couleur: '#9ca3af', label: 'Type indéterminé' }
          ].map((item, index) => (
            <div key={index} style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px'
            }}>
              <div style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: item.couleur,
                marginRight: '8px'
              }}/>
              <span>{item.label}</span>
            </div>
          ))}
        </div>

        {/* Liste des cavités */}
        {cavitesJson.map(cavite => {
          const coords = convertToWGS84(cavite.xouvl2e, cavite.youvl2e);
          return (
            <div key={cavite.id} style={{
              backgroundColor: '#f8fafc',
              padding: '15px',
              borderRadius: '8px',
              marginBottom: '10px'
            }}>
              <h3 style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginBottom: '5px'
              }}>{cavite.nomCavite}</h3>
              <p style={{ fontSize: '12px', color: '#666' }}>Type: {cavite.typeCavite}</p>
              <div style={{ fontSize: '11px', color: '#888', marginTop: '5px' }}>
                <p>Lambert II: {cavite.xouvl2e}, {cavite.youvl2e}</p>
                {coords && (
                  <p>WGS84: {coords.lat.toFixed(6)}, {coords.lon.toFixed(6)}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Carte */}
      <div id="map" style={{
        flex: 1
      }}></div>
    </div>
  );
}

export default App;